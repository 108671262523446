import React, { useState, useEffect } from "react";
import "./ReadDocument.css"; // Asegúrate de tener un archivo CSS para los estilos

const ReadDocument = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);

  // Obtener los registros desde el endpoint
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await fetch("https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/read");
        if (!response.ok) {
          throw new Error(`Error fetching records: ${response.statusText}`);
        }
        const data = await response.json();
        setRecords(data);
        setFilteredRecords(data); // Inicialmente, la tabla mostrará todos los registros
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchRecords();
  }, []);

  // Filtrar los registros según el término de búsqueda
  useEffect(() => {
    const filtered = records.filter((record) => {
      const fullName = `${record.nombre} ${record.apellido}`.toLowerCase();
      const searchTermLower = searchTerm.toLowerCase();

      // Filtrado flexible para buscar en nombre o apellido
      return (
        fullName.includes(searchTermLower) || 
        (record.nombre && record.nombre.toLowerCase().includes(searchTermLower)) ||
        (record.apellido && record.apellido.toLowerCase().includes(searchTermLower)) ||
        (record.name && record.name.toLowerCase().includes(searchTermLower)) ||
        (record.lastname && record.lastname.toLowerCase().includes(searchTermLower))
      );
    });
    setFilteredRecords(filtered);
  }, [searchTerm, records]);

  // Función para extraer el nombre del archivo
  const getFileName = (filePath) => {
    const parts = filePath.split('/');
    const fileName = parts[parts.length - 1];
    return fileName;
  };

  return (
    <div className="contenedor-read">
      <h1>Dear {user?.username}</h1>

      {/* Barra de búsqueda */}
      <input
        type="text"
        placeholder="Search by name or lastname"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />

      {/* Contenedor para el desplazamiento horizontal de la tabla */}
      <div className="table-container">
        {/* Tabla de registros */}
        <table className="document-table">
          <thead>
            <tr>
              <th>Name</th> {/* Columna para nombre */}
              <th>Lastname</th> {/* Columna para apellido */}
              <th>Day</th>
              <th>Month</th>
              <th>Year</th>
              <th className="download-links-col">Download Links</th>
            </tr>
          </thead>
          <tbody>
            {filteredRecords.map((record, index) => (
              <tr key={index}>
                <td>{record.name || record.nombre}</td> {/* Muestra 'name' si existe, sino 'nombre' */}
                <td>{record.lastname || record.apellido}</td> {/* Muestra 'lastname' si existe, sino 'apellido' */}
                <td>{record.Dia}</td>
                <td>{record.Mes}</td>
                <td>{record.Año}</td>
                <td className="download-links-col">
                  {record.file_urls?.map((url, fileIndex) => (
                    <div key={fileIndex}>
                      <a
                        href={url}
                        download
                        className="download-link"
                      >
                        ➤ {getFileName(record.files[fileIndex])}
                      </a>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReadDocument;
