import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import "./UploadDocuments.css";

const UploadDocuments = ({ user }) => {
  const [files, setFiles] = useState([]);
  const [metadata, setMetadata] = useState({
    name: "",
    lastname: "",
    Dia: "",
    Mes: "",
    Año: "",
  });
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setMetadata((prevMetadata) => ({
      ...prevMetadata,
      Dia: currentDay.toString(),
      Mes: currentMonth.toString(),
      Año: currentYear.toString(),
    }));
  }, []);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMetadata((prevMetadata) => ({
      ...prevMetadata,
      [name]: value,
    }));
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (files.length === 0) {
      alert("Please select at least one file");
      return;
    }
  
    try {
      // Generar un request_id único
      const requestId = uuidv4();
  
      // Paso 1: Solicitar URLs públicas
      const response = await fetch("https://oo2x7rr4xu4kfstl27gkjbjtpa0botzz.lambda-url.us-east-1.on.aws/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          metadata: metadata,
          files: files.map((file) => ({ file_name: file.name })),
          request_id: requestId, // Enviar el request_id generado
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error getting file URLs: ${response.status} ${errorText}`);
      }
  
      const { file_urls } = await response.json();
  
      if (!file_urls || !Array.isArray(file_urls)) {
        throw new Error(`Invalid response: 'file_urls' is missing or not an array.`);
      }
  
      // Paso 2: Subir archivos directamente a S3 usando las URLs públicas
      await Promise.all(
        file_urls.map(async ({ file_name, url }) => {
          const file = files.find((f) => f.name === file_name);
          if (!file) throw new Error(`File ${file_name} not found`);
  
          const uploadResponse = await fetch(url, {
            method: "PUT",
            headers: { "Content-Type": file.type },  // Tipo de archivo
            body: file,  // El contenido del archivo
          });
  
          if (!uploadResponse.ok) {
            const errorDetails = await uploadResponse.text();
            throw new Error(`Failed to upload file ${file_name}: ${errorDetails}`);
          }
  
          // Cambié el log para no mostrar la URL de S3
          console.log(`File ${file_name} uploaded successfully.`);
        })
      );
  
      alert("All files uploaded successfully.");
      setFiles([]);
    } catch (error) {
      console.error("Error:", error);
      alert(`There was a problem uploading the files: ${error.message}`);
    }
  };
  

  return (
    <div className="contenedor-document">
      <h1>Dear {user?.username ? user.username : ""}</h1>
      <p>Welcome to B1Z</p>

      <form onSubmit={handleSubmit} className="upload-form">
        <div
          className={`dropzone ${isDragging ? "dragging" : ""}`}
          onClick={handleDropzoneClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleDropzoneClick();
            }
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="form-control"
            multiple
            style={{ display: "none" }}
          />
          <span>Drag files here or click to select</span>
        </div>

        {files.length > 0 && (
          <div className="file-list">
            <h3>Selected Files:</h3>
            <ul>
              {files.map((file) => (
                <li key={file.name}>
                  {file.name}
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(file.name)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={metadata.name}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastname">Last name:</label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            value={metadata.lastname}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        <button type="submit" className="btn-submit">
          Upload Documents
        </button>
      </form>
    </div>
  );
};

export default UploadDocuments;
